import { createApp } from 'vue';
import router from './router';
import App from './App.vue';
import '@/assets/index.css';
import '@/assets/iconfont.css';
import tmap from '@map-component/vue-tmap';
//==设置全局缓存==
import { createPinia } from 'pinia';
import { useCacheStore } from './stores/cacheStore';
import element from 'element-plus';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);
//==使用缓存==
//const cacheStore = useCacheStore();
//cacheStore.set('myKey', 'myValue');
//const value = cacheStore.get('myKey'); // 'myValue'
//==使用缓存 end==
//==设置全局缓存 end==

createApp(App).use(router).use(tmap).use(element).use(pinia).mount('#app');
