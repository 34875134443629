// stores/cacheStore.js
import { defineStore } from 'pinia';

export const useCacheStore = defineStore({
  id: 'cache',
  state: () => ({
    cache: {}
  }),
  actions: {
    set(key, value) {
      this.cache[key] = value;
    },
    get(key) {
      return this.cache[key];
    }
  },
  persist: {
    // 指定需要持久化的属性。默认持久化所有属性。
    paths: ['cache'],
    // 指定持久化的方式。默认localStorage。
    storage: sessionStorage
  }
});
