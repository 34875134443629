import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    path: '/',
    name: 'main',
    redirect: '/index'
  },
  {
    path: '/index',
    name: 'index',
    component: () => import('@/pages/index.vue')
  },
  {
    path: '/detail',
    name: 'detail',
    component: () => import('@/pages/detail.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/pages/login.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/pages/admin.vue')
  },
  {
    path: '/drive',
    name: 'drive',
    component: () => import('@/pages/drive.vue')
  },
  {
    path: '/call',
    name: 'call',
    component: () => import('@/pages/call.vue')
  }
];
// 配置路由
const router = createRouter({
  history: createWebHashHistory(),
  routes: routes
});

export default router;
